//! Minimize non-type imports

//////////////////////////////////////////////////////////////////////////*
//* Exposed API
export type Version = { version: string }
export type Endpoints = {
    "/auth/google": {
        Q: { accessToken: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/microsoft": {
        Q: { idToken: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/magic-link": {
        Q: { email: string, slug: string } & Version,
        S: { success: boolean }
    },
    "/auth/otp": {
        Q: { token: string } & Version,
        S: { slug: string, token: string }
    },
    "/auth/oidc": {
        Q: { currentUrl: string, verifier: string, slug: string } & Version,
        S: { token: string }
    },
    "/auth/oidc/redirect": {
        Q: { slug: string } & Version,
        S: { url: string, verifier: string }
    }
}

export type Messages = {
    'error': { error: string },
    "bind": { token: string, tenant: string },
    "bound": { id: string, username: string, staff: boolean },

    '🧠/status': {},
    '🧠:status': { status: 'warm' | 'cold' | 'warming' | 'cooling' },
    '🧠/warm': {},
    '🧠/cool': {},

    'attachment/create': { id: string, filename: string },
    'attachment:upload': { filename: string, url: string, key: string },
    'attachment/parse': { id: string, key: string },

    'tenant/create': { name: string, slug: string },
    'tenant/list': {},
    'tenant:list': HLTenant[],
    '△:tenant': HLTenant,

    'reference/create': { filename: string },
    'reference:upload': { filename: string, url: string, id: string },
    'reference/parse': { id: string },
    'reference/load': { id: string },
    'reference:load': HLReference,
    'reference/list': {},
    'reference:list': HLReferenceMeta[],
    'reference/delete': { id: string },
    'reference/disable': { id: string, sectionId: string },
    'reference/page': { id: string, pageId: string },
    'reference:page': { image: string, id: string },
    'reference/download': { id: string },
    'reference:download': { url: string },
    'reference/chat': { id: string, message: string },
    'reference:chat': { messagePart?: string, sections?: HLReference['sections'] },
    'reference/chat-all': { message: string, conversationId: string, history?: { message: string, type: 'query' | 'response' }[], summaries?: string[] },
    'reference:chat-all': { conversationId: string, messagePart?: string, sections?: HLReference['sections'], title?: string, summary?: string },
    '△:reference': HLReference,

    'solicitation/create': { filename: string },
    'solicitation:upload': { filename: string, url: string, id: string },
    'solicitation/parse': { id: string },
    'solicitation/load': { id: string },
    'solicitation:load': HLSolicitation,
    'solicitation/list': {},
    'solicitation:list': HLSolicitationMeta[],
    'solicitation/delete': { id: string },
    'solicitation/page': { id: string, pageId: string },
    'solicitation:page': { image: string, id: string },
    'solicitation/download': { id: string },
    'solicitation:download': { url: string },
    'solicitation/chat': { id: string, message: string },
    'solicitation:chat': { messagePart?: string, sections?: { id: string, title: string, markdown: string, tags: string[] }[] },
    'solicitation/tag': { id: string, sectionId: string, tag: string },
    'solicitation/extract': { id: string },
    'solicitation/outline': { id: string },
    '△:solicitation': HLSolicitation,

    'solutioning/load': { id: string },
    'solutioning:load': HLSolutioningItem[],
    'solutioning/update': { id: string, itemId: string, question: string, answer: string },
    'solutioning/add': { id: string },
    'solutioning/delete': { id: string, itemId: string },
    'solutioning/answer': { id: string },
    '△:solutioning': { id: string },

    'compliance/load': { id: string },
    'compliance:load': HLComplianceItem[],
    'compliance/check': { id: string },
    '△:compliance': { id: string },

    'response/update': { id: string, sectionId: string, data: { title?: Maybe<string>, points?: Maybe<string>, order?: Maybe<number>, paragraphs?: Maybe<number> } },
    'response/outline': { id: string, instructions: string },
    'response/references': { id: string, sectionId: string, references: Maybe<string[]> }
    'response/load': { id: string },
    'response:load': HLResponseSection[],
    'response/write': { id: string, sectionId: string, instructions: Maybe<string> },
    'response/export': { id: string },
    'response:export': { url: string },
    'response/create': { id: string, prevId: Maybe<string> },
    'response/delete': { id: string, sectionId: string },
    '△:response': { id: string },
}

export interface SocketData {
    token: string
}


//////////////////////////////////////////////////////////////////////////*
//* Common Types

export interface HLTenant {
    id: string
    name: string
    slug: string
    credits: Maybe<number>
    users_with_access: string[]
}

export interface HLReference {
    id: string
    created: string
    name: string
    status: "created" | "processing" | "parsed" | "indexed" | "deleted"

    title: Maybe<string>
    thumbnail: Maybe<string>

    pages: {
        id: string
        pageNumber: number
        markdown: Maybe<string>
        tags: string[]
    }[]

    sections: {
        id: string
        sectionNumber: number
        markdown: string
        title: string
        enabled: boolean
        referenceId: string
    }[]
}

export interface HLReferenceMeta {
    id: string
    created: string
    name: string
    status: "created" | "processing" | "parsed" | "indexed" | "deleted"
    title: Maybe<string>
    thumbnail: Maybe<string>
}

export interface HLSolicitation {
    id: string
    created: string
    name: string
    status: "created" |
    "processing" |
    "parsed" |
    "tagging" |
    "extraction" |
    "solutioning" |
    "generating" |
    "outlining" |
    "writing" |
    "checking" |
    "compliance" |
    "complete" |
    "deleted" |
    "error"

    title: Maybe<string>
    thumbnail: Maybe<string>
    markdown: Maybe<string>

    bluf: Maybe<string>
    structure: Maybe<string>

    shallStatements: string[]
    attachments: string[]

    meta: HLSolicitationMetadata[]
    pages: HLSolicitationPage[]
    sections: HLSolicitationSection[]
}

export interface HLSolicitationMeta {
    id: string
    created: string
    name: string
    status: "created" |
    "processing" |
    "parsed" |
    "tagging" |
    "extraction" |
    "solutioning" |
    "generating" |
    "outlining" |
    "writing" |
    "checking" |
    "compliance" |
    "complete" |
    "deleted" |
    "error"
    title: Maybe<string>
    thumbnail?: Maybe<string>
    bluf: Maybe<string>
}

export interface HLSolicitationMetadata {
    id: string
    key: string
    value: string
}

export interface HLSolicitationPage {
    id: string
    pageNumber: number
    markdown: Maybe<string>
    tags: string[]
}

export interface HLSolicitationSection {
    id: string
    markdown: string
    title: string
    tags: string[]
    score?: Maybe<string | number>
}

export interface HLSolutioningItem {
    id: string
    question: string
    answer: Maybe<string>
}

export interface HLComplianceItem {
    id: string
    text: string
    completed: boolean
    answer: Maybe<string>
    basis: string
    category: Maybe<string>
    sections: string[]
}

export interface HLResponseSection {
    id: string
    order: number
    title: string
    points: string

    parentId: Maybe<string>
    references: {
        id: string
        sectionNumber: number
        markdown: string
        title: string
        enabled: boolean
        referenceId: string
    }[]
    paragraphs: HLResponseParagraph[]
}

export interface HLResponseParagraph {
    id: string
    paragraphNumber: number
    markdown: string
}

//////////////////////////////////////////////////////////////////////////*
//* Networking & SockPuppet
export type Maybe<T> = T | null | undefined
export type Handler<Q, S> = (q: Q) => S | Promise<S>
export type Payload<S> = (S | {
    error: string
}) & {
    statusCode?: number
}
export type Endpoint = keyof Endpoints;
export type Request<E extends Endpoint> = Endpoints[E]["Q"]
export type Response<E extends Endpoint> = Endpoints[E]["S"]
export type Route<E extends Endpoint> = Handler<Request<E>, Payload<Response<E>>>

export type Event = keyof Messages;
export type Message<E extends Event> = Messages[E]
export type Channel<E extends Event> = ({ pub, sub }: PubSub) => (m: Message<E>) => void | Promise<void> | any | Promise<any>
export type Port<E extends Event> = Handler<Message<E>, void>
export interface Socketish {
    id: string
    on: (e: string, f: (d: any) => void | any | never | Promise<void> | Promise<any> | Promise<never>) => void
    emit: (e: string, v: any) => void
    join(rooms: string | string[]): Promise<void> | void
    disconnect(close?: boolean): any
    data: SocketData
}
export interface IOish {
    emit: (e: string, v: any) => void
    to(room: string): IOish
}
export const pubsub = (socket: Socketish, io: IOish) => ({
    socket,
    sub: <E extends Event>(e: E, f: Port<E>) =>
        // @ts-ignore
        socket.on(e, f),
    pub: <E extends Event>(e: E, v: Message<E>) => socket.emit(e, v),
    broadcast: <E extends Event>(e: E, v: Message<E>, room: string) => io.to(room).emit(e, v),
})
export type PubSub = ReturnType<typeof pubsub>
export const receiver = (ps: PubSub) => <E extends Event>(e: E, f: Channel<E>) => ps.sub(e, f(ps))
