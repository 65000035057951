import { ref } from "@vue/reactivity"
import type { HLSolutioningItem } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { solicitation } from "@app/state/solicitation"

//? Load
export const solutioning = ref<HLSolutioningItem[]>([])
export const loadSolutioning = (id: string) => {
  if (id !== solicitation.value?.id) solutioning.value = []
  sock.pub('solutioning/load', { id })
}
BIND('solutioning:load', () => s => solutioning.value = s.sort((a, b) => {
  if (a.question === 'Add a question here...') return -1
  if (b.question === 'Add a question here...') return 1
  return a.id.localeCompare(b.id)
}))

//? Update
export const updateSolutioning = (itemId: string, question: string, answer: string) =>
  sock.pub('solutioning/update', { id: solicitation.value!.id, itemId, question, answer })

//? Add
export const addSolutioning = () =>
  sock.pub('solutioning/add', { id: solicitation.value!.id })

//? Delete
export const deleteSolutioning = (itemId: string) =>
  sock.pub('solutioning/delete', { id: solicitation.value!.id, itemId })

//? Auto-Answer
export const answerSolutioning = () =>
  sock.pub('solutioning/answer', { id: solicitation.value!.id })

//? Delta
BIND('△:solutioning', () => s => s.id === solicitation.value?.id ? loadSolutioning(s.id) : null)