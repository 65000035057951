<script async setup lang="ts">
import { isStaff, Tenant, userName } from '@app/state/auth';
import { Icon } from '@iconify/vue'

</script>

<template>
  <div class="empty" v-if="isStaff">
    <Icon icon="lucide:chef-hat" class="icon-size" />
    Welcome back, Chef {{ userName }}.
    <br>
    You have been authenticated with staff access.
  </div>
  <div class="empty" v-else>
    <Icon icon="lucide:donut" class="icon-size" />
    You are now connected to {{ Tenant?.name }}.
    <br>
    Welcome to GovPro Bid, {{ userName }}.
  </div>
</template>

<style scoped lang="scss">
.main {
  padding: 20px;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px var(--primary-background-color);
  border-radius: var(--primary-border-radius);
  flex-wrap: wrap;
  align-content: flex-start;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  width: 100%;
  color: var(--primary-font-color);
  flex-direction: column;
  text-align: center;
  .icon-size {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
  }
}
</style>