<script async setup lang="ts">
import { ref } from '@vue/reactivity';
import { Button } from '@app/components/ui/button'
import { Textfield } from '@app/components/ui/textfield';
import { Icon } from '@iconify/vue'
import { API, slug, version } from '@app/state/common';

const visible = ref(false)
const email = ref('')
const sent = ref(false)
const error = ref(false)

const auth = async () => {
    if (!slug) throw new Error('Slug is required')
    console.log("Requesting magic link for", email.value)
    const { success } = await API('/auth/magic-link', { email: email.value, slug, version })
    if (!success) error.value = true
    sent.value = true
}
</script>

<template>
  <template v-if="!sent">
    <Button v-if="!visible" variant="outline" type="button" @click="visible = true">
        <Icon icon="tabler:mail" class="brand-icon" />
        Sign in with Email
    </Button>
    <Textfield v-if="visible" v-model="email" placeholder="Email address" autofocus @submit="auth" />
  </template>
  <template v-else>
    <p v-if="error" class="text-center text-red-400">Sorry, you haven't been granted access.</p>
    <p v-else class="text-center text-teal-400">Please check your email for a magic link.</p>
  </template>
</template>

<style lang="scss" scoped>
.brand-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}
</style>