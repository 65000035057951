<script async setup lang="ts">
import { Icon } from '@iconify/vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import Markdown from '@app/components/common/Markdown.vue'
import { references } from '@app/state/reference';
import type { HLReference } from '@Heirloom/common';
import { ref, watch } from '@vue/reactivity'

const props = defineProps<{
  reference: HLReference["sections"][number]
}>()

const referenceDoc = ref(references.value.find(r => r.id === props.reference.referenceId))

const expand = ref(false)
</script>

<template>
  <div class="reference" :key="reference.id">
    <div class="title" @click.stop.prevent="expand = !expand">
      <Badge style="display: unset; max-width: 500px">
        {{ referenceDoc?.name ?? referenceDoc?.title ?? reference.id }}
      </Badge>
      {{ reference.title }}
      <Icon :icon="expand ? 'lucide:chevron-down' : 'lucide:chevron-right'" />
    </div>
    <div v-if="expand" class="markdown">
      <div>
        "{{ referenceDoc!.title }}"
      </div>
      <Markdown class="content" :markdown="reference.markdown" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  user-select: none;
  gap: 2px;
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background-color: var(--primary-background-color-accent);
  width: fit-content;
  padding: 2px 4px;
  border-radius: var(--primary-border-radius);

  img {
    width: 10px;
    margin-top: -1px;
  }
}

.markdown {
  margin-top: 8px;
  margin-left: 2px;
  padding-left: 8px;
  border-left: 1px solid var(--primary-background-color);
  .content {
    background-color: var(--secondary-background-color);
    color: var(--primary-font-color);
    border-radius: var(--primary-border-radius);
    padding: 8px;
    font-size: 12px !important;
  }
}
</style>