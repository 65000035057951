import { ref } from "@vue/reactivity"
import type { HLSolicitation, HLSolicitationMeta, Maybe } from "@Heirloom/common"
import { sock, BIND } from "@app/state/common"
import { app, tab, tabs, App } from "@app/state/view"
import Logger from "@app/utils/logger"
const Log = new Logger("Solicitation", { bgColor: '#ccff99' })


//? Load
export const solicitation = ref<Maybe<HLSolicitation>>()
export const loadSolicitation = (id: string) => {
  if (id !== solicitation.value?.id) solicitation.value = null
  sock.pub('solicitation/load', { id })
}
BIND('solicitation:load', () => r => solicitation.value = r)

//? List
export const solicitations = ref<HLSolicitationMeta[]>([])
export const listSolicitations = () => sock.pub('solicitation/list', {})
BIND('solicitation:list', () => rs => solicitations.value = rs.sort((a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf()))

//? Delta
BIND('△:solicitation', () => r => r.id === solicitation.value?.id ? loadSolicitation(r.id) : listSolicitations())

//? Create
const files: Record<string, File> = {}
export const createSolicitation = async (file: File) => {
  Log.info(`Creating solicitation for <${file.name}>`, file)
  sock.pub('solicitation/create', { filename: file.name })
  files[file.name] = file
}
BIND('solicitation:upload', ({ pub }) => ({ filename, url, id }) => {
  if (!url) throw new Error("Did not receive a presigned URL.")
  const file = files[filename]
  if (!file) throw new Error("Do not have file to upload.")

  Log.log(`Received presigned URL. Beginning upload of <${filename}>`)
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    Log.success(`Successfully uploaded <${filename}>`)
    Log.info(`Parsing solicitation ${id} <${filename}>`)
    delete files[filename]
    pub('solicitation/parse', { id })
  }
  xhr.open('PUT', url)
  xhr.send(file)
  Log.log(`PUT <${filename}> → [${url.slice(0, 30)}...]`)
})

//? View page
export const getSolicitationPage = (pageId: string) => sock.pub('solicitation/page', { id: solicitation.value!.id, pageId })
export const solicitationPage = ref<Maybe<{ image: string, id: string }>>()
BIND('solicitation:page', ({ pub }) => ({ image, id }) => {
  solicitationPage.value = { image, id }
})

//? Delete
export const deleteSolicitation = (id: Maybe<string>) => {
  if (!id || typeof id !== 'string') id = solicitation.value?.id;
  if (!id) return;
  sock.pub('solicitation/delete', { id })
  tabs.value = tabs.value.filter(tab => tab.id !== id)
  if (tab.value?.id === id) {
    tab.value = undefined
  }
  app.value = App.NONE
  solicitation.value = null
}

//? Download
export const downloadSolicitation = (id: Maybe<string>) => {
  if (!id || typeof id !== 'string') id = solicitation.value?.id;
  if (!id) return;
  sock.pub('solicitation/download', { id })
}
BIND('solicitation:download', () => ({ url }) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
})

//? Chat
export const answer = ref<Maybe<string>>()
export const context = ref<{ id: string, title: string, markdown: string, tags: string[] }[]>([])
export const chatSolicitation = (message: string) => {
  if (!solicitation.value) return;
  answer.value = ''
  context.value = []
  sock.pub('solicitation/chat', { id: solicitation.value?.id, message })
}
BIND('solicitation:chat', () => ({ messagePart, sections }) => {
  if (messagePart) answer.value += messagePart
  if (sections) {
    context.value = []
    context.value.push(...sections)
  }
})

//? Tag
export const tagSection = (sectionId: string, tag: string) => {
  if (!solicitation.value) return;
  sock.pub('solicitation/tag', { id: solicitation.value?.id, sectionId, tag })
}

//? Extract
export const extractSolicitation = () => {
  if (!solicitation.value) return;
  sock.pub('solicitation/extract', { id: solicitation.value?.id })
}

//? Outline
export const outlineSolicitation = () => {
  if (!solicitation.value) return;
  sock.pub('solicitation/outline', { id: solicitation.value?.id })
}

//? Attachments
export const addAttachment = (file: File) => {
  if (!solicitation.value) return;
  files[file.name] = file
  sock.pub('attachment/create', { id: solicitation.value.id, filename: file.name })
}
BIND('attachment:upload', ({ pub }) => async ({ filename, url, key }) => {
  if (!url) throw new Error("Did not receive a presigned URL.")
  const file = files[filename]
  if (!file) throw new Error("Do not have file to upload.")

  Log.log(`Received presigned URL. Beginning upload of <${filename}>`)
  const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    if (!solicitation.value) return;
    Log.success(`Successfully uploaded <${filename}>`)
    Log.info(`Parsing attachment ${key} <${filename}>`)
    delete files[filename]
    pub('attachment/parse', { key, id: solicitation.value.id })
  }
  xhr.open('PUT', url)
  xhr.send(file)
  Log.log(`PUT <${filename}> → [${url.slice(0, 30)}...]`)
})

