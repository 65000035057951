import type { HLReferenceMeta, HLSolicitationMeta, Maybe } from '@Heirloom/common';
import { ref, computed } from '@vue/reactivity';

export enum App {
  WRITE="Write", LIBRARY="Library", FEED="Feed", CHAT="Chat", TAB="", NONE="Home"
}
export const app = ref(App.NONE)
export const min = ref(false)

interface ReferenceTab {
  name: string
  type: "reference"
  id: string
  reference: HLReferenceMeta
}

interface SolicitationTab {
  name: string
  type: "solicitation"
  id: string
  solicitation: HLSolicitationMeta
}

interface ChatTab {
  name: string
  type: "chat"
  id: string
}

export type Tab = ReferenceTab | SolicitationTab | ChatTab
export const tabs = ref<Tab[]>([])
export const tab = ref<Maybe<Tab>>()

export enum Segment { Markdown, Sections, Pages }
export const segment = ref<Segment>(Segment.Sections)

//? Colors
const colorScheme = ref(window.matchMedia('(prefers-color-scheme: light)').matches ? 'light' : 'dark')
window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', e => colorScheme.value = e.matches ? 'light' : 'dark')

export const green = computed(() => colorScheme.value === 'light' ? '#169055' : '#5ffcb3')
export const red = computed(() => colorScheme.value === 'light' ? '#c83360' : '#eb6e93')
export const greenBg = computed(() => colorScheme.value === 'light' ? '#16905540' : '#5ffcb340')
export const redBg = computed(() => colorScheme.value === 'light' ? '#c8336040' : '#eb6e9340')

export const snowing = ref(false)
