<script async setup lang="ts">
import type { HLReferenceMeta } from "@Heirloom/common"
import Button from "@app/components/ui/button/Button.vue"
import { Icon } from "@iconify/vue"
import { support } from "@app/state/common"
import { deleteReference, downloadReference, listReferences, references, retryReference } from "@app/state/reference"
import { App, app, tab, tabs } from "@app/state/view"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@app/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@app/components/ui/table"

listReferences()

const openReference = (reference: HLReferenceMeta) => {
  const exists = tabs.value.find(
    (tab) => tab.type === "reference" && tab.id === reference.id
  )
  if (exists) tab.value = exists
  else
    tab.value =
      tabs.value[
        tabs.value.push({
          name: reference.name.replace(".pdf", ""),
          type: "reference",
          id: reference.id,
          reference,
        }) - 1
      ]
  app.value = App.TAB
}
</script>

<template>
  <Table class="reference-list" v-if="references.length > 0">
    <TableHeader>
      <TableRow>
        <TableHead>Reference</TableHead>
        <TableHead>Upload Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow
        @click="openReference(reference)"
        v-for="reference in references"
        :key="reference.id"
        :reference="reference"
        class="reference-line"
      >
        <TableCell>{{
          reference.name.replace(".pdf", "") || "Untitled"
        }}</TableCell>
        <TableCell>
          <div class="status">
            <Icon icon="lucide:clock" />
            {{ new Date(reference.created).toNicerDateTime() }}
          </div>
        </TableCell>
        <TableCell>
          <div class="status">
            <div
              class="red"
              @click.stop.prevent="support"
              v-if="
                reference.status != 'indexed' &&
                new Date(reference.created).valueOf() <
                  Date.now() - 1000 * 60 * 20
              "
            >
              <Icon class="status-icon"  icon="lucide:bug" color="#c75957" />
              Contact Support
            </div>
            <template v-else>
              <div class="blue" v-if="reference.status === 'created'">
                <Icon class="status-icon"  icon="lucide:cloud-upload" color="#5b83ff" />
                Uploading...
              </div>
              <div class="blue" v-if="reference.status === 'processing'">
                <Icon class="status-icon"  icon="lucide:loader" color="#5b83ff" />
                Parsing...
              </div>
              <div class="blue" v-if="reference.status === 'parsed'">
                <Icon class="status-icon"  icon="lucide:text-quote" color="#5b83ff" />
                Indexing...
              </div>
              <div class="green" v-if="reference.status === 'indexed'">
                <Icon class="status-icon"  icon="lucide:circle-check-big" color="#58c693" />
                Ready to use
              </div>
            </template>
          </div>
        </TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button @click.stop.prevent variant="secondary">
                <span class="mr-4">Actions</span>
                <Icon icon="lucide:chevron-down" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuItem @click="downloadReference(reference.id)">
                  <Icon icon="lucide:download" class="mr-2" />
                  <span>Download</span>
                </DropdownMenuItem>
                <DropdownMenuItem @click="retryReference(reference.id)">
                  <Icon icon="lucide:rotate-ccw" class="mr-2" />
                  <span>Retry</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="deleteReference(reference.id)">
                <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                <span class="text-red-500">Delete</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>

<style scoped lang="scss">
.reference-list {
  margin-top: 20px;
}

.reference-line {
  cursor: pointer;
  user-select: none;
  height: 50px;
}

.status,
.status div {
  white-space: nowrap;
  display: inline-flex;
}

.status-icon {
  margin-right: 4px;
  margin-top: 3px;
}

.red {
  color: #c75957;
}

.blue {
  color: #5b83ff;
}

.green {
  color: #58c693;
}

thead {
  text-align: left;
}
</style>
