<script async setup lang="ts">
import type { HLSolicitationMeta } from "@Heirloom/common"
import Button from "@app/components/ui/button/Button.vue"
import { Icon } from "@iconify/vue"
import { support } from "@app/state/common"
import {
  deleteSolicitation,
  downloadSolicitation,
  listSolicitations,
  solicitations,
} from "@app/state/solicitation"
import { App, app, tab, tabs } from "@app/state/view"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@app/components/ui/dropdown-menu"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogTrigger,
} from "@app/components/ui/dialog"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@app/components/ui/table"
import { downloadOutline } from "@app/state/response"

listSolicitations()

const openSolicitation = (solicitation: HLSolicitationMeta) => {
  const exists = tabs.value.find(
    (tab) => tab.type === "solicitation" && tab.id === solicitation.id
  )
  if (exists) tab.value = exists
  else
    tab.value =
      tabs.value[
        tabs.value.push({
          name: solicitation.name.replace(".pdf", ""),
          type: "solicitation",
          id: solicitation.id,
          solicitation,
        }) - 1
      ]
  app.value = App.TAB
}
</script>

<template>
  <Table class="solicitation-list" v-if="solicitations.length > 0">
    <TableHeader>
      <TableRow>
        <TableHead>Solicitation</TableHead>
        <TableHead>Upload Date</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Actions</TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow
        @click="openSolicitation(solicitation)"
        v-for="solicitation in solicitations"
        :key="solicitation.id"
        :solicitation="solicitation"
        class="solicitation-line"
      >
        <TableCell>{{
          solicitation.name.replace(".pdf", "") || "Untitled"
        }}</TableCell>
        <TableCell>
          <div class="status">
            <Icon class="status-icon" icon="lucide:clock" />
            {{ new Date(solicitation.created).toNicerDateTime() }}
          </div>
        </TableCell>
        <TableCell>
          <div class="status">
            <div
              class="red"
              @click.stop.prevent="support"
              v-if="
                solicitation.status === 'error' &&
                new Date(solicitation.created).valueOf() <
                  Date.now() - 1000 * 60 * 20
              "
            >
              <Icon class="status-icon"  icon="lucide:bug" color="#c75957" />
              Contact Support
            </div>
            <template v-else>
              <div class="blue" v-if="solicitation.status === 'created'">
                <Icon class="status-icon" icon="lucide:cloud-upload" color="#5b83ff" />
                Created
              </div>
              <div class="blue" v-if="solicitation.status === 'processing'">
                <Icon class="status-icon"  icon="lucide:loader" color="#5b83ff" />
                Processing
              </div>
              <div class="blue" v-if="solicitation.status === 'parsed'">
                <Icon class="status-icon"  icon="lucide:text-quote" color="#5b83ff" />
                Parsed
              </div>
              <div class="blue" v-if="solicitation.status === 'tagging'">
                <Icon class="status-icon"  icon="lucide:tag" color="#5b83ff" />
                Tagging
              </div>
              <div class="blue" v-if="solicitation.status === 'extraction'">
                <Icon class="status-icon"  icon="lucide:file-output" color="#5b83ff" />
                Extracting
              </div>
              <div class="blue" v-if="solicitation.status === 'solutioning'">
                <Icon class="status-icon"  icon="lucide:lightbulb" color="#5b83ff" />
                Solutioning
              </div>
              <div class="blue" v-if="solicitation.status === 'generating'">
                <Icon class="status-icon"  icon="lucide:cpu" color="#5b83ff" />
                Generating
              </div>
              <div class="blue" v-if="solicitation.status === 'outlining'">
                <Icon class="status-icon"  icon="lucide:list" color="#5b83ff" />
                Outlining
              </div>
              <div class="blue" v-if="solicitation.status === 'writing'">
                <Icon class="status-icon"  icon="lucide:pen-tool" color="#5b83ff" />
                Writing
              </div>
              <div class="blue" v-if="solicitation.status === 'checking'">
                <Icon class="status-icon"  icon="lucide:check-circle" color="#5b83ff" />
                Checking
              </div>
              <div class="blue" v-if="solicitation.status === 'compliance'">
                <Icon class="status-icon"  icon="lucide:shield-check" color="#5b83ff" />
                Compliance Check
              </div>
              <div class="green" v-if="solicitation.status === 'complete'">
                <Icon class="status-icon"  icon="lucide:circle-check-big" color="#58c693" />
                Complete
              </div>
              <div class="red" v-if="solicitation.status === 'deleted'">
                <Icon class="status-icon"  icon="lucide:trash-2" color="#c75957" />
                Deleted
              </div>
            </template>
          </div>
        </TableCell>
        <TableCell>
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <Button @click.stop.prevent variant="secondary">
                <span class="mr-4">Actions</span>
                <Icon icon="lucide:chevron-down" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Icon icon="lucide:download" class="mr-2" />
                    Download
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      <DropdownMenuItem
                        @click="downloadSolicitation(solicitation.id)"
                      >
                        <Icon icon="lucide:scroll-text" class="mr-2" />
                        <span>Solicitation</span>
                      </DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
                <Dialog>
                  <DialogTrigger as-child>
                    <DropdownMenuItem @select.prevent>
                      <Icon icon="lucide:trash" class="text-red-500 mr-2" />
                      <span class="text-red-500">Delete</span>
                    </DropdownMenuItem>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Delete Solicitation</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>
                      Any progress towards a draft will be lost. Are you sure
                      that you want to proceed?
                      <br /><br />
                      <DialogClose>
                        <Button @click="deleteSolicitation(solicitation.id)" variant="destructive">
                          <template #icon>
                            <Icon icon="lucide:trash" />
                          </template>
                          Delete
                        </Button>
                      </DialogClose>
                    </DialogDescription>
                  </DialogContent>
                </Dialog>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
</template>

<style scoped lang="scss">
.solicitation-list {
  margin-top: 20px;
}

.solicitation-line {
  cursor: pointer;
  user-select: none;
  height: 50px;
}

.status,
.status div {
  white-space: nowrap;
  display: inline-flex;
}

.status-icon {
  margin-right: 4px;
  margin-top: 3px;
}

.red {
  color: #c75957;
}

.blue {
  color: #5b83ff;
}

.green {
  color: #58c693;
}

thead {
  text-align: left;
}
</style>
