<script async setup lang="ts">
import EditableText from '@app/components/common/EditableText.vue';
import Markdown from '@app/components/common/Markdown.vue';
import Smore from '@app/components/response/Smore.vue';
import Reference from '@app/components/response/Reference.vue';
import EditResponse from '@app/components/response/EditResponse.vue';
import { createSection, deleteSection, recalculateReferences, updateSection, writeResponse, type HLResponseSectionTree } from '@app/state/response';
import { ref } from '@vue/reactivity';
import Paragraph from '@app/components/typography/Paragraph.vue';
import Small from '@app/components/typography/Small.vue';
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue';
import { Separator } from '@app/components/ui/separator'


import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@app/components/ui/tabs"
import { Icon } from '@iconify/vue';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/components/ui/collapsible'
import Rows from '@app/components/ui/flex/Rows.vue'
import Columns from '@app/components/ui/flex/Columns.vue';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  ContextMenuShortcut,
} from '@app/components/ui/context-menu'
import { isStaff } from '@app/state/auth';

const props = defineProps<{
  section: HLResponseSectionTree
}>()

const n_paragraphs = ref(props.section.paragraphs.length + '')

const shiftUp = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order - 1,
  paragraphs: undefined
})
const shiftDown = () => updateSection(props.section.id, {
  ...props.section,
  order: props.section.order + 1,
  paragraphs: undefined
})
const editTitle = () => updateSection(props.section.id, {
  ...props.section,
  title: props.section.title,
  paragraphs: undefined
})
const editPoints = () => updateSection(props.section.id, {
  ...props.section,
  points: props.section.points,
  paragraphs: undefined
})
const editParagraphs = () => updateSection(props.section.id, {
  ...props.section,
  paragraphs: parseInt(n_paragraphs.value),
})
</script>

<template>
  <ContextMenu>
    <ContextMenuTrigger>
      <Rows>
        <Card>
          <Columns padding="14px 15px 14px 13px">
            <Columns>
              <Collapsible type="single">
                <Columns flex="1">
                  <CollapsibleTrigger :value="section.id" :key="section.id" class="text-left">
                    <Columns>
                      <Columns flex="1">
                        <Columns width="fit-content">
                          <EditableText @submit="editTitle" v-model="section.title" />
                        </Columns>
                      </Columns>
                      <Columns width="40px">
                        <Button variant="secondary" size="icon">
                          <Icon icon="lucide:chevrons-up-down" />
                        </Button>
                      </Columns>
                    </Columns>
                  </CollapsibleTrigger>
                </Columns>
                <CollapsibleContent :value="section.id">
                  <Tabs default-value="outline" class="tabs-root">
                    <Columns margin="5px 0 0 0">
                      <TabsList>
                        <TabsTrigger value="outline">
                          <Icon height="20px" icon="lucide:table-of-contents" />
                        </TabsTrigger>
                        <TabsTrigger value="content">
                          <Icon height="20px" icon="lucide:text" />
                        </TabsTrigger>
                      </TabsList>
                    </Columns>

                    <Rows>
                      <TabsContent value="outline">
                        <Paragraph>Obligations</Paragraph>
                        <Small>
                          <EditableText @submit="editPoints" v-model="section.points" multiline />
                        </Small>
                        <Paragraph>References</Paragraph>
                        <Reference v-for="reference in section.references" :reference="reference" />
                      </TabsContent>
                      <TabsContent value="content">
                        <Paragraph>Paragraphs</Paragraph>
                        <Small>
                          <Markdown v-for="paragraph in section.paragraphs" :key="paragraph.id"
                            :markdown="paragraph.markdown" />
                        </Small>
                      </TabsContent>
                    </Rows>
                  </Tabs>
                </CollapsibleContent>
              </Collapsible>
            </Columns>
          </Columns>
        </Card>
      </Rows>
    </ContextMenuTrigger>
    <ContextMenuContent>
      <ContextMenuItem @click="() => createSection(section.id)">
        Add section
        <ContextMenuShortcut>
          <Icon icon="lucide:plus" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => shiftUp()">
        Shift up
        <ContextMenuShortcut>
          <Icon icon="lucide:chevron-up" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => shiftDown()">
        Shift down
        <ContextMenuShortcut>
          <Icon icon="lucide:chevron-down" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => recalculateReferences(section.id)">
        <span class="mr-2">Re-Reference</span>
        <ContextMenuShortcut>
          <Icon icon="lucide:refresh-ccw" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => writeResponse(section.id)">
        Regenerate
        <ContextMenuShortcut>
          <Icon icon="lucide:pencil" />
        </ContextMenuShortcut>
      </ContextMenuItem>
      <ContextMenuItem @click="() => deleteSection(section.id)">
        Delete
        <ContextMenuShortcut>
          <Icon icon="lucide:trash" />
        </ContextMenuShortcut>
      </ContextMenuItem>
    </ContextMenuContent>
  </ContextMenu>
  <div @click="() => createSection(section.id)" class="add-divider">
    <Separator label="+" />
  </div>
</template>

<style scoped lang="scss">
.add-divider {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  padding: 10px;
  margin: 5px;

  &:hover {
    opacity: 1;
  }
}
</style>
