<script setup lang="ts">
import type { HLResponseSectionTree } from '@app/state/response';
import Section from '@app/components/response/Section.vue';
import Rows from '@app/components/ui/flex/Rows.vue';

defineProps<{
  sections: HLResponseSectionTree[]
  parentId?: string
}>()
</script>

<template>
  <Rows v-for="section in sections">
    <Section :key="section.id" :section="section" />
    <Rows padding="0 0 0 24px" v-if="section.children">
      <Section v-for="child in section.children" :key="child.id" :section="child" />
    </Rows>
  </Rows>
</template>

<style lang="scss" scoped></style>